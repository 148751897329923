import React from "react";
import Main from "../Main/Main";


function Wall() {
  

  return (
    <>
      <Main />
    </>
  );
}

export default Wall;
